/** @jsx jsx */
import { jsx, Box, Heading } from 'theme-ui'
import React, { useEffect } from 'react'

import { isEmpty, complement } from 'ramda'

import { SEO } from 'components'

function WebcamPage() {
  return (
    <>
      <SEO title="Webcam" />

      <Box
        sx={{
          paddingX: 'm',
          paddingY: ['l', 'xl', 'xxl', 'xxxl'],
          minHeight: '50vh',
        }}
      >
        <div
          style={{
            maxWidth: '1280px',
            aspectRatio: '1280 / 720',
            margin: 'auto',
          }}
        >
          <RailCam />
        </div>
      </Box>
    </>
  )
}

const RailCam = () => {
  useEffect(() => {
    const callCam = (partnerCode) => {
      const xhttp = new XMLHttpRequest()
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          document.getElementById('RCYT1').src = this.responseText
        }
      }
      xhttp.open(
        'GET',
        `https://railcam.uk/partners/getYoutubeUrl.php?c=${partnerCode}`,
        true
      )
      xhttp.send()
    }

    callCam('RHDSI654')
  }, [])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
      }}
      dangerouslySetInnerHTML={{
        __html: `
          <iframe id="RCYT1" width="100%" height="100%" src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        `,
      }}
    />
  )
}

export default WebcamPage
